<template>
    <slot :entries="entries" />
</template>

<script setup>
import DiscoverEntriesQuery from '~/graphql/queries/discover/discoverEntries.graphql';

const props = defineProps({
    variables: {
        type: Object,
        default: () => ({})
    }
});

const { variables: defaultVariables } = useDefaultVariables();
const { variables: propsVariables } = toRefs(props);

const parsedQueryVariables = computed(() => {
    const varia = {
        ...defaultVariables.value,
        ...propsVariables.value,
        limit: propsVariables.value.limit ?? 6,
    };

    if (propsVariables.value?.tagIds?.length > 0) {
        varia.tagIds = propsVariables.value.tagIds;
    }

    if (propsVariables.value?.showOnHome) {
        varia.showOnHome = propsVariables.value.showOnHome;
    }

    if (propsVariables.value?.showInStream) {
        varia.showInStream = propsVariables.value.showInStream;
    }

    return varia;
});

const { data } = await useAsyncQuery({
    query: DiscoverEntriesQuery,
    variables: parsedQueryVariables
});

const entries = computed(() => data.value?.entries ?? []);
</script>
